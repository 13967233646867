import * as React from "react";

import { Box } from "@components/box";

export const BlogArticleContainer = (props: React.PropsWithChildren<{}>) => (
  <Box display="flex" className="justify-center">
    <Box display="flex" className="mt-[88px] flex-col mx-[22px] md:w-auto sm:mx-0">
      {props.children}
    </Box>
  </Box>
);

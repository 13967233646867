import React from "react";
import { graphql, navigate } from "gatsby";
import { PageLayout, SEO } from "@components/page-layout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Box } from "@components/box";
import {
  BlogArticleSidebar,
  DownloadAppBox,
  DownloadAppRating,
  BlogSubscribeEmail,
  ShareArticle,
  BlogSubscribe,
  BlogArticleAboutAuthor,
  // BlogArticleRelatedArticles,
  BlogArticleBrowseCategories,
  // BlogArticleCard,
  BlogArticleCategory,
  BlogArticleCategories,
  BlogArticleSidebarFollowUs,
  BlogArticleSidebarICash,
  BlogArticleSidebarInstantLoan,
  BlogArticleSidebarSection,
  // BlogArticleSidebarTopArticle,
  // BlogArticleSidebarTopArticles,
  Spacer,
} from "@brainfinance/icash-component-library";
import { Products } from "@containers/products";
import { PageHeading, Paragraph, Subheading } from "@components/typography";
import { GatsbyLink } from "@components/gatsby-link";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { BlogArticleContainer } from "@containers/blog-article";

import defaultImg from "../images/default.png";
import ios from "@images/iOS.svg";
import android from "@images/Android.svg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const isRelatedToProduct = false;

const BlogArticleTemplate = (props: any) => {
  const { article, allCategories }: any = props.data;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const imgAlt = node.data.target.description || "iCash image";
        const gatsbyImage = getImage(node.data.target.gatsbyImageData);
        if (gatsbyImage)
          return (
            <Box display="flex" className="w-full justify-center py-[2rem]">
              <GatsbyImage id={node.data.target.contentful_id} image={gatsbyImage} alt={imgAlt} loading="lazy" />
            </Box>
          );
        return null;
      },
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <Box className="py-[2rem]">
          <PageHeading>{children}</PageHeading>
        </Box>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <Box className="py-[1.5rem]">
          <Subheading>{children}</Subheading>
        </Box>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <Subheading className="p-0 !text-[17px]">{children}</Subheading>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <Paragraph className="pb-[22px] text-interface-300" style={{ whiteSpace: "pre-line" }}>
          {children}
        </Paragraph>
      ),
      [INLINES.HYPERLINK]: (_node: any, children: any) => (
        <GatsbyLink
          target="_blank"
          to={_node.data.uri}
          rel={_node.data.uri.startsWith("https://icash.ca") ? "noopener" : "noopener nofollow"}
        >
          {children}
        </GatsbyLink>
      ),
      [BLOCKS.UL_LIST]: (_node: any, children: any) => <ul>{children}</ul>,
    },
  };

  return (
    <PageLayout>
      <SEO title={article.metaTitle} description={article.metaDescription} noindex={article.noIndex} />
      <BlogArticleContainer>
        <div className="flex max-w-[1509px] mx-auto mb-[44px] px-[22px] md:block md:max-w-[540px] md:mb-[22px] sm:max-w-[353px]">
          <div className="max-w-[1047px] pl-[189px] relative md:max-w-full md:pl-0">
            <div className="max-w-[858px] md:max-w-full">
              <GatsbyImage
                className="h-[483px] w-full object-cover bg-center rounded-[30px] mb-[39px] md:h-[338px] md:mb-[17px] sm:h-[221px]"
                image={article.mainImage.gatsbyImageData}
                alt={article.mainImage.description}
                loading="lazy"
              />
              <div className="flex items-center mb-[22px] md:mb-[10px]">
                <BlogArticleCategories className="mr-[22px] md:mr-[6px]">
                  {article.categories?.map((category: any) => (
                    <BlogArticleCategory
                      key={category?.id}
                      className="!my-[5px] md:!my-[5px]"
                      category={category?.name}
                      onClick={() => navigate(`/blog/${category?.slug}/`)}
                    />
                  ))}
                </BlogArticleCategories>
                <span className="text-[15px] leading-[20px] text-interface-300 tracking-[-.25px] md:text-[13px] md:leading-[18px] md:tracking-[-.15px] mb-[10px] md:mb-[6px]">
                  {new Date(article.publicationDate).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })}
                </span>
              </div>
              <div className="global--heading text-interface-500 mb-[22px]">{article.h1}</div>
              {renderRichText(article.article, options)}
              <ShareArticle
                className="top-[598px] left-0"
                onFacebookShare={() => console.log("share article on facebook")}
                onTwitterShare={() => console.log("share article on twitter")}
                onLinkedInShare={() => console.log("share article on linkedin")}
                onEmailShare={() => console.log("share article with email")}
                onCopyLink={() => console.log("copy link")}
              />
              <Spacer />
              <BlogArticleAboutAuthor
                name={article.author?.name}
                description={article.author?.description?.description}
                imgUrl={article.author?.picture?.gatsbyImageData.images.fallback.src || defaultImg}
                onClick={() => navigate(`/blog/${article.author?.slug}/`)}
              />
            </div>
          </div>
          <BlogArticleSidebar>
            {isRelatedToProduct ? (
              <BlogArticleSidebarInstantLoan handleApply={() => console.log("applied!")} />
            ) : (
              <BlogArticleSidebarICash handleApply={() => console.log("applied!")} />
            )}
            <BlogArticleSidebarSection>
              <DownloadAppBox downloadLink="https://icash-ca.app.link/7MQFYetR9X" iosLink="https://icash-ca.app.link/URLVQwAR9X" androidLink="https://icash-ca.app.link/7MQFYetR9X" iosImg={ios} androidImg={android}>
                The iCash mobile app and borrow up to $1,500 anywhere, at any time.
              </DownloadAppBox>
              <DownloadAppRating iosRating="7.3k" iosScore="4.8" androidRating="3.8k" androidScore="4.2" />
            </BlogArticleSidebarSection>
            <BlogArticleSidebarSection title="Newsletter" className="md:hidden">
              <BlogSubscribeEmail handleSubscribe={(email: string) => alert(`Subscribe ${email}`)} />
            </BlogArticleSidebarSection>
            <BlogArticleSidebarSection className="mb-[45px] md:hidden" title="Categories">
              <div className="flex flex-wrap">
                {allCategories.nodes.map((category: any) => (
                  <BlogArticleCategory
                    key={category.slug}
                    category={category.name}
                    hasArrow
                    onClick={() => navigate(`/blog/${category.slug}/`)}
                  />
                ))}
              </div>
            </BlogArticleSidebarSection>
            {/* <BlogArticleSidebarSection title="Top articles" className="md:hidden">
              <BlogArticleSidebarTopArticles>
                {TOP_ARTICLES.map((article, idx) => (
                  <BlogArticleSidebarTopArticle key={idx} {...article} />
                ))}
              </BlogArticleSidebarTopArticles>
            </BlogArticleSidebarSection> */}
            <BlogArticleSidebarSection title="Follow us" className="md:hidden">
              <BlogArticleSidebarFollowUs
                facebookUrl="https://www.facebook.com/icash.ca/"
                twitterUrl="https://twitter.com/iCASH_ca"
                instagramUrl="https://www.instagram.com/icash.ca/"
                linkedinUrl="https://www.linkedin.com/company/icash-ca"
              />
            </BlogArticleSidebarSection>
          </BlogArticleSidebar>
        </div>
      </BlogArticleContainer>
      <div className="mb-[44px] mx-auto max-w-[1320px] md:mb-[22px]">
        <BlogSubscribe
          className="md:mx-[-1rem] md:!w-[calc(100%+2rem)]"
          handleSubscribe={() => console.log("subscribed!")}
        >
          <span className="text-brand-green">{"Enjoyed "}</span>
          <span>this read</span>
        </BlogSubscribe>
      </div>
      {/* <div className="mb-[44px] max-w-[1320px] mx-auto md:max-w-full">
        <BlogArticleRelatedArticles>
          {mockRelatedArticles.map((relatedArticle) => (
            <BlogArticleCard
              key={relatedArticle.id}
              title={relatedArticle.title}
              description={relatedArticle.description}
              author={relatedArticle.author}
              authorImgSrc={relatedArticle.authorImgSrc}
              date={relatedArticle.date}
              imgSrc={relatedArticle.imgSrc}
              url={relatedArticle.url}
            >
              <BlogArticleCategories>
                {relatedArticle.categories.map((category, idx) => (
                  <BlogArticleCategory
                    key={`${category}-${idx}`}
                    category={category}
                    onClick={(category: string) => navigate(`/blog/${category.toLocaleLowerCase().replace(/ /g, '-')}/`)}
                  />
                ))}
              </BlogArticleCategories>
            </BlogArticleCard>
          ))}
        </BlogArticleRelatedArticles>
      </div> */}
      <div className="mb-[44px] px-[14px]">
        <BlogArticleBrowseCategories className="hidden md:block">
          {allCategories.nodes.map((category: any) => (
            <BlogArticleCategory
              key={category.slug}
              category={category.name}
              hasArrow
              onClick={() => navigate(`/blog/${category.slug}/`)}
            />
          ))}
        </BlogArticleBrowseCategories>
      </div>
      <div className="mb-[44px]">
        <Products />
      </div>
    </PageLayout>
  );
};

export default BlogArticleTemplate;

export const pageQuery = graphql`
  query BlogArticleQuery($slug: String!) {
    article: contentfulBlogArticle(slug: { eq: $slug }) {
      id
      slug
      metaTitle
      metaDescription
      publicationDate(formatString: "MMMM DD, YYYY")
      author {
        name
        slug
        description {
          description
        }
        picture {
          gatsbyImageData(width: 100, quality: 100)
        }
      }
      categories {
        slug
        name
      }
      h1
      noIndex
      article {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
          }
          description
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 900)
        }
      }
      mainImage {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 900)
        description
      }
    }

    allCategories: allContentfulBlogCategory {
      nodes {
        name
        slug
      }
    }
  }
`;
